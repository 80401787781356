<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Return inventory
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                v-model="inventory.bar_code"
                                @input="inventory.bar_code = $event !== null ? $event : ''"
                                clearable
                                :error="$v.inventory.bar_code.$error"
                            >
                                <template v-slot:label>
                                    <span class="text-danger">*</span> Enter bar code
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="barCodeNotFound">No record found of given bar code.</span>
                            <span class="text-danger" v-if="$v.inventory.bar_code.$error">Please enter a barcode</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Comment <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="inventory.comment" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.inventory.comment.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn
                                    v-if="checkIsAccessible('gate-pass', 'create')"
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="returnInventory"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Confirm
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import InventoryAccessionService from "@/core/services/inventory-accession/InventoryAccessionService";

const inventoryAccession = new InventoryAccessionService();

export default {
    validations: {
        inventory:{
            bar_code: {required},
            comment: {}
        }
    },
    data() {
        return {
            dialog: false,
            isBusy: false,
            inventoryAssignedListId: null,
            inventory:{
                bar_code: null,
                inventory_assigned_list_id: null,
                comment: ''
            },
            barCodeNotFound: false,
            editorConfig: {
                versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
        };
    },
    methods: {
        openDialog(id = null) {
            this.dialog = true;
            this.inventoryAssignedListId = id;
        },
        hideModal() {
            this.dialog = false;
        },
        resetForm() {
            this.dialog = false;
            this.isBusy = false;
            this.inventory ={
                bar_code: null,
                inventory_assigned_list_id: null,
                comment: ''
            };
            this.inventoryAssignedListId = null;
            this.barCodeNotFound = false;
        },
        returnInventory(){
            this.inventory.inventory_assigned_list_id = this.inventoryAssignedListId;
            this.$v.inventory.$touch();

            if (this.$v.inventory.$error) {
                setTimeout(() => {
                    this.$v.inventory.$reset();
                }, 3000);
            } else {
                this.returnInventoryAccession();
            }
        },
        returnInventoryAccession(){
            this.isBusy = true;
            inventoryAccession
            .returnAccession(this.inventory)
            .then((res) => {
                if(res.data.status == 'not_found'){
                    this.barCodeNotFound = true;
                }else{
                    this.barCodeNotFound = false;
                    this.$snotify.success("Information updated");
                    this.resetForm();
                    this.$emit('refresh');
                }
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            })
            .finally(() => {
                this.isBusy = false;
            })
        }
    },
}
</script>
