<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage inventory assign list</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Inventory assign list
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('inventory-stock-register', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addInventoryAssignmentList">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add inventory assign list
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="8">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title" outlined clearable
                                        v-on:keyup.enter="getInventoryAssignedList()" dense>
                                    </v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="searchInventoryAssignedList()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Inventory item</th>
                                        <th class="px-3 wrap-column">User</th>
                                        <th class="px-3 wrap-column">Assigned date</th>
                                        <th class="px-3 wrap-column">Authorised date</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="inventoryAssignedLists.length > 0" v-for="(item, index) of inventoryAssignedLists" :key="index">
                                        <td class="px-3 wrap-column">
                                            {{ item.inventory_item_title }}
                                            <div class="mt-2">
                                                <strong>Quantity: </strong>
                                                {{ item.qty ? item.qty : 'NA'}}
                                            </div>
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.user_full_name ? item.user_full_name : 'NA'}}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.formatted_assigned_date ? item.formatted_assigned_date : '-'}}
                                        </td>
                                        <td class="px-3 wrap-column">
                                            {{ item.authorised_date ? item.authorised_date : '-'}}
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="editInventoryAssignmentList(item.id)" v-if="checkIsAccessible('inventory-stock-register', 'edit')">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text v-if="item.is_returnable && checkIsAccessible('inventory-stock-register', 'return')" tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="returnInventoryAccession(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-arrow-rotate-left"></i>
                                                            </span>
                                                            <span class="navi-text"> Return </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('inventory-stock-register', 'delete')" >
                                                        <a href="#" class="navi-link" @click="deleteInventoryAssignmentList(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="inventoryAssignedLists.length == 0">
                                        <td class="text-center  px-3" colspan="7">
                                          <strong>No data available to display.</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="inventoryAssignedLists.length > 0" 
                                @input="getInventoryAssignedList" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <return-inventory-accession
            ref="return-inventory-accession"
            @refresh="getInventoryAssignedList"
        ></return-inventory-accession>

    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import InventoryAssignedListService from "@/core/services/inventory-assigned-list/InventoryAssignedListService";
import ReturnInventoryAccession from "./ReturnInventoryAccession";

const inventoryAssignedList = new InventoryAssignedListService();

export default {
    components:{
        ReturnInventoryAccession
    },
    data() {
        return {
            search: {
                title: '',
            },
            loading: false,
            inventoryAssignedLists: [],
            page: null,
            perPage: null,
            total: null,
        }
    },
    methods: {
        searchInventoryAssignedList(){
            this.page = 1;
            this.getInventoryAssignedList();
        },  
        getInventoryAssignedList() {
            this.loading = true;
            inventoryAssignedList
                .paginate(this.search, this.page)
                .then(response => {
                    this.inventoryAssignedLists = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addInventoryAssignmentList() {
            this.$router.push({
                name: 'create-inventory-assigned-list'
            });
        },
        editInventoryAssignmentList(id) {
            this.$router.push({
                name: 'edit-inventory-assigned-list',
                params: {
                    inventoryAssignedListId: id
                }
            });
        },
        deleteInventoryAssignmentList(id){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        inventoryAssignedList
                        .delete(id)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getInventoryAssignedList();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        returnInventoryAccession(id){
            this.$refs['return-inventory-accession'].openDialog(id);
        }
    },
    mounted() {
        this.getInventoryAssignedList();
    }
}
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>